import { useState, useGlobal } from "reactn";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "reactn";
import { deleteData, postData, putData } from "../../../api/api";
import Button from "../../button/button.component";
import SwitchButton from "../../form/toggle-button/toggle-button.component";
import InputField from "../../form/input-field/input-field.component";
import Select from "../../form/select/select.component";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { toast } from "react-toastify";

const ComplaintModal = ({
  open,
  currentUser,
  complaint,
  onHide,
  onBlurHide = false,
  onUpdated = () => {},
  access,
}) => {
  const [complaintData, setComplaintData] = useState({
    description: "",
    note: " ",
    response: "",
    status: "Pending",
  });

  useEffect(() => {
    if (open) {
      setComplaintData({
        description: complaint?.description,
        note: complaint?.note,
        response: complaint?.response,
        status: complaint?.status,
      });
    }
  }, [open]);

  const [state, setState] = useState({
    processing: false,
    error: null,
    success: null,
  });

  const reset = () => {
    setState({
      processing: false,
      error: null,
      success: null,
    });
  };

  const onSubmit = (action) => {
    setState({
      ...state,
      processing: true,
      error: null,
    });

    //return;
    const request = putData(
      `complaint/${complaint?.id}`,
      { ...complaintData },
      currentUser?.token
    );

    request.then((resp) => {
      if (resp.success) {
        setState({ ...state, processing: false, error: null });
        onHide();
        onUpdated();
        toast.success("Complaint updated.");
        return;
      }
      toast.error("An error occurred!");
      setState({
        ...state,
        processing: false,
        error: resp?.message || `Unable to update Complaint`,
      });
    });
  };

  return (
    <div>
      <Modal
        //className="custom-modal"
        show={open}
        onHide={() => {
          if (onBlurHide) onHide();
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <div className="f-width d-flex space-between">
            <div className="title">Complaint</div>
            <div
              onClick={() => {
                if (state.processing) return;
                reset();
                onHide();
              }}
            >
              <Close />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="scrollbar">
          <div className=" mb-50 mt-20">
            <div className="text-12">Description</div>
            <div className="mb-2">{complaint?.description}</div>

            <div className="text-12">Note</div>
            <div className="mb-2">{complaint?.note}</div>

            <div className="text-12">Response</div>
            <textarea
              className="form-control w-full mb-2"
              value={complaintData?.response}
              onChange={(e) => {
                setComplaintData({
                  ...complaintData,
                  response: e.target.value,
                });
              }}
            ></textarea>

            <div className="">
              <div className="text-12">Status</div>
              <Select
                value={{
                  label: complaintData?.status,
                  value: complaintData?.status,
                }}
                placeholder=""
                small
                options={[
                  { label: "Pending", value: "Pending" },
                  { label: "Response", value: "Response" },
                  { label: "Resolved", value: "Resolved" },
                ]}
                onChange={(selected) => {
                  setComplaintData({
                    ...complaintData,
                    status: selected.value,
                  });
                }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {state.error && (
            <div className="text-danger text-right mb-20">{state?.error}</div>
          )}
          <div className="flex flex-end">
            {access?.write && (
              <Button
                classes="small danger"
                loading={state.processing}
                disabled={state.processing}
                onClick={onSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ComplaintModal;
