import { useState, useGlobal } from "reactn";
import { withRouter } from "react-router-dom";
import Sidebar from "../sidebar/sidebar.component";
import "./page-layout.styles.scss";
import InputField from "../form/input-field/input-field.component";
import Icons from "../../assets/icons";
import { ReactComponent as ProfilePic } from "../../assets/icons/profile-pic.svg";
import { useEffect, useRef } from "react";
import useAccessLevels from "../../hooks/useAccessLevels";

const PageLayout = (props) => {
  const {
    children,
    activeLink,
    parentMenu = "dashboard",
    inputProps = { show: true, value: "", onChange: (e) => {} },
    ...otherProps
  } = props;

  const [toggle, setToggle] = useState("hide");
  const [currentUser, setCurrentUser] = useGlobal("currentUser");

  // Local state for input value
  const [localInputValue, setLocalInputValue] = useState(inputProps.value);
  const debounceTimeoutRef = useRef(null);

  const handleLocalInputChange = (e) => {
    const value = e.target.value; // Update the local state immediately
    setLocalInputValue(value);

    // Debounce the parent onChange handler
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      
      inputProps?.onChange({ target: { value: value } });
    }, 900);
  };

  const onToggle = () => setToggle(toggle === "show" ? "hide" : "show");

  const { hasAccess, accessRoutes } = useAccessLevels(
    currentUser?.user?.accessLevels
  );

  useEffect(() => {
    const routes = accessRoutes();
    if (activeLink && props.history?.location?.pathname?.includes("/admin/")) {
      const access = hasAccess(activeLink);
      if (!access.read) {
        props?.history?.replace(`/admin/${routes[0]}`);
      }
    }
  }, [props?.history?.location?.pathname]);

  return (
    <div className="page-layout">
      <Sidebar
        active={activeLink}
        parentMenu={parentMenu}
        toggle={toggle}
        onToggle={onToggle}
        {...otherProps}
      />
      <div
        className="header-container"
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="header-bar">
          <div className="menu-icon" onClick={onToggle}>
            <div>
              <Icons.Programs variant="active" color="" />
            </div>
          </div>
          <div
            className="back-icon pointer"
            onClick={() => props.history.goBack()}
          >
            <Icons.ArrowRight variant="default" />
          </div>
          <div className="f-width">
            {inputProps && (
              <div className="input-bar">
                <InputField
                  isSearch
                  transparent={true}
                  classes="rounded"
                  placeholder="Search"
                  value={localInputValue} // Controlled by local state
                  onChange={handleLocalInputChange}
                />
              </div>
            )}
          </div>
          <div
            className="notification-icon"
            onClick={() => props.history.push(`/updates`)}
          >
            {" "}
            <Icons.Bell />{" "}
          </div>
          <div
            className="user-info"
            onClick={() => props.history.push(`/account`)}
          >
            <div>
              <ProfilePic />
            </div>
            {/*<div className="user-initials">NA</div> */}
          </div>
        </div>
      </div>
      <div className="page-content" onContextMenu={(e) => e.preventDefault()}>
        <div className="page-inner">{children}</div>
      </div>
    </div>
  );
};

export default withRouter(PageLayout);
